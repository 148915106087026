import { InjectionKey } from 'vue'
import { createStore, useStore as useVuexStore, Store as VuexStore } from 'vuex'

import {
  executeRestAction,
  failedRestAction,
  initialRestAction,
  RestAction,
  successRestAction,
} from '@/states/restAction'
import { deepCopy } from '@/helpers/deepCopy'
import PaymentTypeProvider from '@/providers/paymenttypeprovider'
import PaymentType from '@/models/paymenttype'

export interface PaymentTypeState {
  provider: PaymentTypeProvider
  ready: boolean
  values: RestAction<PaymentType[]>
}

const initialState: PaymentTypeState = {
  provider: new PaymentTypeProvider(),
  ready: false,
  values: initialRestAction(),
}

const _excludedPaymentTypeIds = [
  6,  // Bonzai
  27, // Arrondi
  31, // Como
]

export const paymentTypeState: VuexStore<PaymentTypeState> =
  createStore<PaymentTypeState>({
    state: initialState,
    mutations: {
      ready(state, payload: boolean) {
        state.ready = payload
      },
      init(state) {
        state = initialState
      },
      load(state, payload: Partial<PaymentTypeState>) {
        state.ready = true
        if (payload.values) state.values = deepCopy(payload.values)
      },
    },
    getters: {
      all: (state) => state.values.data ?? [],
      backoffice: (state) =>
        state.values.data?.filter(
          (paymentType) =>
            paymentType.retail &&
            !paymentType.transfer &&
            !paymentType.free_payment &&
            !_excludedPaymentTypeIds.includes(paymentType.id!)
        ) ?? [],
      free: (state) =>
        state.values.data?.filter((paymentType) => paymentType.free_payment) ??
        [],
      byId: (state) => (paymentTypeId: number): PaymentType | undefined =>
        state.values.data?.find(
          (paymentType) => paymentType.id === paymentTypeId
        ) ?? undefined,
    },
    actions: {
      async load(store) {
        store.commit('ready', false)
        store.state.values = executeRestAction()
        try {
          store.commit('load', {
            values: successRestAction(
              await store.state.provider.fetchPaymentTypes()
            ),
          })
        } catch (e: any) {
          store.state.values = failedRestAction(e.message)
        }
      },
    },
    plugins: [(store) => store.dispatch('load')],
  })

export const paymentTypeStateKey: InjectionKey<VuexStore<PaymentTypeState>> =
  Symbol()
export const usePaymentTypeState = (): VuexStore<PaymentTypeState> =>
  useVuexStore(paymentTypeStateKey)
export default usePaymentTypeState
