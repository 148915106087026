import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'

export interface INotificationParams {
    detail?: string;
    life?: number;
    sticky?: boolean;
}

export interface IConfirmationParams {
    acceptLabel?: string;
    rejectLabel?: string;
    accepted?: Function;
    rejected?: Function;
}

export default function useNotification() {
    const toast = useToast();
    const confirm = useConfirm();

    const defaultLife = 10000;

    const getLife = (params?: INotificationParams) => {
        if (!params) return defaultLife;

        if (params?.sticky) return undefined;

        return params?.life ?? defaultLife;
    }

    const showSuccess = (message: string, params?: INotificationParams) => {
        toast.add({
            severity: "success",
            summary: message,
            detail: params?.detail ?? '',
            life: getLife(params),
            contentStyleClass: 'py-4'
        });
    };

    const showInfo = (message: string, params?: INotificationParams) => {
        toast.add({
            severity: "info",
            summary: message,
            detail: params?.detail ?? '',
            life: getLife(params),
            contentStyleClass: 'py-4'
        });
    };

    const showWarning = (message: string, params?: INotificationParams) => {
        toast.add({
            severity: "warn",
            summary: message,
            detail: params?.detail ?? '',
            life: getLife(params),
            contentStyleClass: 'py-4'
        });
    };

    const showError = (message: string, params?: INotificationParams) => {
        toast.add({
            severity: "error",
            summary: message,
            detail: params?.detail ?? '',
            life: getLife(params),
            contentStyleClass: 'py-4'
        });
    };

    const showAPIError = (message: string, error: any) => {
        const detail = error?.response?.data?.message ?? error?.response?.data ?? error?.response ?? error?.message ?? error;

        // TODO : get message translated based on error's code

        showError(message, {
            detail: detail,
        });
    };

    const askConfirmation = (message: string, params?: IConfirmationParams) => {
        return new Promise(resolve => {
            confirm.require({
                message: message,
                //header: 'Confirmation',
                //icon: 'fa-solid fa-question fa-beat',
                acceptIcon: 'fa-solid fa-check',
                rejectIcon: 'fa-solid fa-xmark',
                accept: () => {
                    if (params?.accepted)
                        resolve(params?.accepted());
                },
                reject: () => {
                    if (params?.rejected)
                        resolve(params?.rejected());
                },
                acceptLabel: params?.acceptLabel,
                rejectLabel: params?.rejectLabel,
            });
        });
    };

    const clear = () => {
        toast.removeAllGroups();
    }

    return {
        showSuccess,
        showInfo,
        showWarning,
        showError,
        showAPIError,
        clear,
        askConfirmation,
        confirm,
    };
}