import GlobalCategory from "../globalcategory";
import Listing from "../listing";
import Reporting from "../reporting";

export default class ListingViewModel {
    public listing?: Listing;
    public globalCategory?: GlobalCategory;
    public reportings?: Reporting[];

    constructor(init?: Partial<ListingViewModel>) {
        Object.assign(this, init);
    }
}