import {InjectionKey} from 'vue';
import {createStore, useStore as useVuexStore, Store as VuexStore} from 'vuex'

import {
    executeRestAction,
    failedRestAction,
    initialRestAction,
    RestAction,
    successRestAction
} from '@/states/restAction';
import Country from '@/models/country';
import {deepCopy} from '@/helpers/deepCopy';
import CountryProvider from '@/providers/countryprovider';

const restomax = [56, 528, 250, 442, 724];

export interface CountryState {
    provider: CountryProvider,
    ready: boolean,
    values: RestAction<Country[]>
}

const initialState: CountryState = {
    provider: new CountryProvider(),
    ready: false,
    values: initialRestAction()
}

export const countryState: VuexStore<CountryState> = createStore<CountryState>({
    state: initialState,
    mutations: {
        ready (state, payload: boolean) { state.ready = payload },
        init (state) { state = initialState },
        load (state, payload: Partial<CountryState>) {
            state.ready = true;
            if (payload.values) state.values = deepCopy(payload.values);
        },
    },
    getters: {
        all: (state) => state.values.data ?? [],
        actives: (state) => state.values.data?.filter(e => e.active) ?? [],
        vat_country_actives: (state) => state.values.data?.filter(e => e.vat_country_active) ?? [],
        restomax: (state) => state.values.data?.filter(e => restomax.includes(e.id!)) ?? [],
        iso_code_2: (state) => (value: string) => state.values.data?.find(e => e.iso_code_2?.toLowerCase() === value.toLowerCase()) ?? null,
        iso_code_3: (state) => (value: string) => state.values.data?.find(e => e.iso_code_3?.toLowerCase() === value.toLowerCase()) ?? null,
        byId: (state: CountryState) => (value: number): Country | null => state.values.data?.find((country: Country) => country.id === value) ?? null
    },
    actions: {
        async load(store) {
            store.commit('ready', false);
            store.state.values = executeRestAction();
            try {
                store.commit('load', {
                    values: successRestAction(await store.state.provider.fetchCountries())
                });
            } catch (e: any) {
                store.state.values = failedRestAction(e.message);
            }
        },
    },
    plugins: [
        (store) => store.dispatch('load')
    ]
})
export const countryStateKey: InjectionKey<VuexStore<CountryState>> = Symbol()
export const useCountryState = (): VuexStore<CountryState> => useVuexStore(countryStateKey);
export default useCountryState;