import BaseProvider from "./baseprovider"
import CAGlobalUsersModel from '@/models/dashboard/caglobalusersmodel';
import CAReportZModel from '@/models/dashboard/careportzmodel';
import CASummaryPaymentsModel from '@/models/dashboard/casummarypaymentsmodel';
import CASummarySalesModel from '@/models/dashboard/casummarysalesmodel';
import GuestCountModel from '@/models/dashboard/guestcountmodel';
import SalesDetailsModel from '@/models/dashboard/salesdetailsmodel';
import CurrentModel from '@/models/dashboard/currentmodel';
import Cached from '@/plugins/cached';
import moment from 'moment/moment';
import { PointGlobalUsersModel } from '@/models/dashboard/pointglobalusersmodel';
import { CAItemLevelModel } from '@/models/dashboard/caItemLevelModel';

export default class DashProvider extends BaseProvider {
    private static longTTL = 1000 * 60 * 30;

    private async getDash<T>(report: string, storeIds: number[], fromDate: Date, toDate: Date, ctor?: new (data: any) => T): Promise<T[]> {
        const currentDate: Date = moment().startOf('day').toDate();
        const fromString: string = moment(fromDate).format("yyyy-MM-DD");
        const toString: string = moment(toDate).format("yyyy-MM-DD");

        const response = await Promise.all([
            Cached.request(
                'GET',
                `dash/${report}/${fromString}/${toString}`,
                { storeIds }, undefined,
                2,
                false,
                toDate < currentDate ? { ttl: DashProvider.longTTL } : true),
            new Promise((resolve) => setTimeout(resolve, 2000))
        ]);

        const r = this.received(response[0], d => ctor ? new ctor(d) : d);
        console.log (report, r);
        return r;
    }

    public getCAReportZ = (storeIds: number[], fromDate: Date, toDate: Date): Promise<CAReportZModel[]> =>
        this.getDash<CAReportZModel>('CAReportZ', storeIds, fromDate, toDate, CAReportZModel);

    public getCASummaryPayments = (storeIds: number[], fromDate: Date, toDate: Date): Promise<CASummaryPaymentsModel[]> =>
        this.getDash<CASummaryPaymentsModel>('CASummaryPayments', storeIds, fromDate, toDate, CASummaryPaymentsModel);

    public getCASummarySales = (storeIds: number[], fromDate: Date, toDate: Date): Promise<CASummarySalesModel[]> =>
        this.getDash<CASummarySalesModel>('CASummarySales', storeIds, fromDate, toDate, CASummarySalesModel);

    public getCurrent = (storeIds: number[], fromDate: Date, toDate: Date): Promise<CurrentModel[]> =>
        this.getDash<CurrentModel>('Current', storeIds, fromDate, toDate, CurrentModel);

    public getGuestCount = (storeIds: number[], fromDate: Date, toDate: Date): Promise<GuestCountModel[]> =>
        this.getDash<GuestCountModel>('GuestCount', storeIds, fromDate, toDate, GuestCountModel);

    public getCAGlobalUsers = (storeIds: number[], fromDate: Date, toDate: Date): Promise<CAGlobalUsersModel[]> =>
        this.getDash<CAGlobalUsersModel>('CAGlobalUsers', storeIds, fromDate, toDate, CAGlobalUsersModel);

    public getPointGlobalUsers = (storeIds: number[], fromDate: Date, toDate: Date): Promise<PointGlobalUsersModel[]> =>
        this.getDash<PointGlobalUsersModel>('PointGlobalUsers', storeIds, fromDate, toDate);

    public getSalesDetails = (storeIds: number[], fromDate: Date, toDate: Date): Promise<SalesDetailsModel[]> =>
        this.getDash<SalesDetailsModel>('SalesDetails', storeIds, fromDate, toDate, SalesDetailsModel);

    public getCAItemLevels = (storeIds: number[], fromDate: Date, toDate: Date): Promise<CAItemLevelModel[]> =>
        this.getDash<CAItemLevelModel>('CAItemLevels', storeIds, fromDate, toDate);
}