<template>
  <Dialog v-if="notificationParams"
          v-model:visible="displayed" :showHeader="false" :closeOnEscape="false"
          :modal="!['info', 'success', 'muted'].includes(notificationParams.type)"
          :position="['info', 'success', 'muted'].includes(notificationParams.type) ? 'top' : 'center'"
          :closable="notificationParams.type == 'error' || notificationParams.showCancel"
          @hide="hide(notificationParams.type == 'error' && !notificationParams.showCancel ? BONotificationValue.OK : BONotificationValue.CANCEL)"
          style="min-width: 30%"
          :contentClass="`border-${notificationParams.type} overflow-hidden`">
    <div v-if="sending" class="overlay"></div>
    <div class="flex justify-content-center toaster-container-title m-4 mt-5"
         :class="`p-${notificationParams.type}`">
      <i v-if="notificationParams.icon" class="mr-3" :class="notificationParams.icon" />
      <i v-else-if="notificationParams.type == 'info'" class="fa fa-info-circle mr-3" />
      <i v-else-if="notificationParams.type == 'error'" class="fa fa-times-circle mr-3" />
      <i v-else-if="notificationParams.type == 'success'" class="fa fa-check-circle mr-3" />
      <i v-else-if="notificationParams.type == 'warning'" class="fa fa-exclamation-triangle mr-3" />
      <i v-else-if="notificationParams.type == 'muted'" class="fa fa-volume-mute mr-3" />
      <i v-else-if="notificationParams.type == 'question'" class="fas fa-question-circle mr-3" />
      <span>{{ notificationParams.title ?? filters.cflt(`toaster_title_${notificationParams.type}`) }}</span>
    </div>
    <div class="flex flex-column w-100 h-100">
      <div v-if="notificationParams.subtitle"
           class="flex justify-content-center text-center toaster-container-subtitle mx-4"
           v-html="notificationParams.subtitle ?? filters.cflt(`toaster_subTitle_${notificationParams.type}`)" />
      <div v-if="notificationParams.message && (!notificationParams.showMore || notificationParams.showMore && more)"
           class="toaster-container-message" :class="{ 'text-center': notificationParams.centered }"
           v-html="notificationParams.message" />
      <div v-if="notificationParams.footer"
           class="flex justify-content-center toaster-container-footer mx-4"
           v-html="notificationParams.footer ?? filters.cflt(`toaster_footer.${notificationParams.type}`)" />
    </div>
    <div class="flex justify-content-between mt-1">
      <div>
        <Button v-if="notificationParams.showSend"
                class="mr-2 border-0 button-error"
                :disabled="sending"
                @click="send()"
                v-html="notificationParams.htmlSend ?? filters.cflt(`toaster_button_send`)" />
        <Button v-if="notificationParams.showMore"
                class="mr-2 border-0 button-error"
                @click="more = !more"
                v-html="more ? notificationParams.htmlLess ?? filters.cflt(`toaster_button_less`) :
                               notificationParams.htmlMore ?? filters.cflt(`toaster_button_more`)" />
      </div>
      <div class="flex flex-row mt-3">
        <Button v-if="notificationParams.showNo"
                class="ml-2 border-0"
                :disabled="sending"
                @click="hide(BONotificationValue.NO)"
                v-html="notificationParams.htmlNo ?? filters.cflt(`toaster_button_no`)" />
        <Button v-if="notificationParams.showYes"
                class="ml-2 border-0"
                :disabled="sending"
                @click="hide(BONotificationValue.YES)"
                v-html="notificationParams.htmlYes ?? filters.cflt(`toaster_button_yes`)" />
        <Button v-if="notificationParams.showCancel"
                :disabled="sending"
                class="ml-2 border-0"
                @click="hide(BONotificationValue.CANCEL)"
                v-html="notificationParams.htmlCancel ?? filters.cflt(`toaster_button_cancel`)" />
        <Button v-if="notificationParams.showOK"
                :disabled="sending"
                class="ml-2 border-0" @click="hide(BONotificationValue.OK)"
                :class="`button-${notificationParams.type}`"
                v-html="notificationParams.htmlOK ?? filters.cflt(`toaster_button_ok`)" />
      </div>
    </div>
  </Dialog>
</template>
<style scoped>
.toaster-container-title {
  font-size: calc(1rem + 1vw);
}

.toaster-container-subtitle {
  font-weight: 600;
}

.toaster-container-message {
  min-height: 60px;
  max-height: 400px;
  margin: 20px 20px 0px;
  overflow: auto;
}

.toaster-container-footer {
  font-weight: 800;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0; left: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

</style>
<script lang="ts">
import {defineComponent, ref,} from 'vue';
import filters from '../../helpers/filters'
import { authState } from '@/states/auth'
import useProvider from '@/hooks/provider';
import useNotification from '@/hooks/notification';
import GlobalUser from '@/models/globaluser';

export type BONotificationType = 'info' | 'success' | 'warning' | 'error' | 'muted' | 'question';

export class BONotificationParams {
  type: BONotificationType = 'info';
  timeout: number = 0;
  icon?: string;
  title?: string;
  subtitle?: string;
  message: string;
  footer?: string;
  centered: boolean = false;
  showYes: boolean = false; htmlYes?: string;
  showNo: boolean = false; htmlNo?: string;
  showOK: boolean = true; htmlOK?: string;
  showCancel: boolean = false; htmlCancel?: string;
  showSend: boolean = false; htmlSend?: string;
  showMore: boolean = false; htmlMore?: string; htmlLess?: string;

  constructor(init: { message: string } & Partial<BONotificationParams>) {
    this.message = init.message;
    Object.assign(this, init);
  }
}

export enum BONotificationValue {
  YES,
  NO,
  OK,
  CANCEL,
  TIMEOUT
}

export default defineComponent({
  setup(props, {emit}) {
    const resolve = ref();
    const reject = ref();

    const boNotification = ref();
    const provider = useProvider();
    const notification = useNotification();

    const displayed = ref<boolean>(false);
    const sending = ref<boolean>(false);
    const more = ref<boolean>(false);

    const timer = ref();

    const notificationParams = ref<BONotificationParams>(new BONotificationParams({ message: 'Unknown message' }));
    const error = ref();

    const show = async (msg: Error | BONotificationParams | string, custom: BONotificationParams | null = null) => {
      if (displayed.value) return;

      error.value = msg instanceof Error ? msg : null;
      if (typeof msg === 'string') msg = { message: msg } as BONotificationParams;

      const t = new BONotificationParams({ message: msg.message });

      t.type = msg instanceof Error ? custom?.type ?? 'error' : msg.type ?? 'info';

      t.timeout = msg instanceof Error ? custom?.timeout ?? 0 :
          msg.timeout ??
            (t.type == 'success' ? 2000 :
             t.type == 'info' ? 10000 :
             t.type == 'muted' ? 10000 :
             0); // Question, warning or error must be clicked

      t.icon = msg instanceof Error ? custom?.icon : (msg as any)?.icon ?? undefined;
      t.title = msg instanceof Error ? custom?.title ?? filters.cflt('toaster_title_error') :
                msg.title ?? filters.cflt(`toaster_title_${t.type}`);

      t.subtitle = (msg instanceof Error) ? (custom?.subtitle ?? msg.message ?? filters.cflt('toaster_subtitle_error')) :
                   (msg.subtitle ?? filters.cflt(`toaster_subtitle_${t.type}`));

      t.message = msg instanceof Error ? custom?.message ?? (msg as any).response?.data?.message : msg.message ?? '';
      t.footer = (msg instanceof Error) ? (custom?.footer ?? filters.cflt('toaster_footer_error')) :
                 (msg.footer ?? filters.cflt(`toaster_footer_${t.type}`));

      t.centered = typeof custom?.centered === 'undefined' ?
                   ['info', 'success', 'muted', 'question'].includes(t.type) :
                   custom.centered;

      t.showYes = msg instanceof Error ? custom?.showYes ?? false : typeof msg.showYes !== 'undefined' ? msg.showYes : msg.type == 'question';
      t.showNo = msg instanceof Error ? custom?.showNo ?? false : typeof msg.showNo !== 'undefined' ? msg.showNo : msg.type == 'question';
      t.showOK = msg instanceof Error ? custom?.showOK ?? true : typeof msg.showOK !== 'undefined' ? msg.showOK : (msg.type !== 'question' && !msg.showYes && !msg.showNo && !msg.showCancel);
      t.showCancel = msg instanceof Error ? custom?.showCancel ?? false : typeof msg.showCancel !== 'undefined' ? msg.showCancel : false;
      t.showSend = msg instanceof Error ? custom?.showSend ?? true : typeof msg.showSend !== 'undefined' ? msg.showSend : false;
      t.showMore = msg instanceof Error ? custom?.showMore ?? true : typeof msg.showMore !== 'undefined' ? msg.showMore : false;

      t.htmlYes = msg instanceof Error ? custom?.htmlYes ?? filters.cflt('toaster_button_yes') :
                  msg.htmlYes ?? filters.cflt('toaster_button_yes');
      t.htmlNo = msg instanceof Error ? custom?.htmlNo ?? filters.cflt('toaster_button_no') :
                 msg.htmlNo ?? filters.cflt('toaster_button_no');
      t.htmlOK = msg instanceof Error ? custom?.htmlOK ?? filters.cflt('toaster_button_ok') :
                 msg.htmlOK ?? filters.cflt('toaster_button_ok');
      t.htmlCancel = msg instanceof Error ? custom?.htmlCancel ?? filters.cflt('toaster_button_cancel') :
                     msg.htmlOK ?? filters.cflt('toaster_button_cancel');

      notificationParams.value = t;
      displayed.value = true;

      if (timer.value) { clearTimeout(timer.value); timer.value = undefined; }
      if (t.timeout > 0) {
        timer.value = setTimeout(() => {
          timer.value = undefined;
          hide(BONotificationValue.TIMEOUT);
        }, t.timeout)
      }

      // Warning: A bug with PrimeVue makes it fail if two notifications are shown one after the other within the same tick.
      // It works fine by upgrading PrimeVue to the latest version, but then layout breaks.
      return new Promise((success, cancel) => {
          resolve.value = (v: BONotificationValue) => { if ((timer.value ?? 0) > 0) { clearTimeout(timer.value); timer.value = undefined; } return success(v); }
          reject.value = (v: BONotificationValue) => { if ((timer.value ?? 0) > 0) { clearTimeout(timer.value); timer.value = undefined; } return cancel(v); }
        });
    }

    const hide = (value: BONotificationValue) => {
      if (displayed?.value) {
        displayed.value = false;
        resolve.value(value);
      }
    }

    const send = async () => {
      try {
        sending.value = true;
        const r = await provider.error.sendError(
            authState.state?.globalUser ?? new GlobalUser() ,
            error.value ?? notificationParams.value
        )
        if (!r) {
          notification.showSuccess(filters.cflt('toaster_send_success'));
          notificationParams.value!.showSend = false;
        }
        else notification.showError(filters.cflt('toaster_send_error') + `\r\n${r}`)
      } catch (e: any) {
        notification.showError(filters.cflt('toaster_send_error') + `\r\n${e.message}`)
      } finally {
        sending.value = false;
      }
    }

    return {
      boNotification,
      authState,
      BONotificationValue,
      filters,
      notificationParams, error,
      show, hide, send,
      displayed, sending, more
    }
  }
})
</script>