export default class Organization {
    public id?: number;
    public uid?: string;
    public name?: string;
    public email?: string;
    public customer_code?: number;
    public is_system: boolean = false;
    public share_customer_with_all_entities: boolean = false;
    public share_vouchers_with_all_entities: boolean = false;
    public webshop_api_url?: string
    public support_logo?: string
    public support_tel?: string
    public support_email?: string
    public support_note?: string
    public support_link?: string

    constructor(init?: Partial<Organization>) {
        Object.assign(this, init);
    }
}