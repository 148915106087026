import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-75ab3bb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "overlay"
}
const _hoisted_2 = {
  key: 1,
  class: "fa fa-info-circle mr-3"
}
const _hoisted_3 = {
  key: 2,
  class: "fa fa-times-circle mr-3"
}
const _hoisted_4 = {
  key: 3,
  class: "fa fa-check-circle mr-3"
}
const _hoisted_5 = {
  key: 4,
  class: "fa fa-exclamation-triangle mr-3"
}
const _hoisted_6 = {
  key: 5,
  class: "fa fa-volume-mute mr-3"
}
const _hoisted_7 = {
  key: 6,
  class: "fas fa-question-circle mr-3"
}
const _hoisted_8 = { class: "flex flex-column w-100 h-100" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "flex justify-content-between mt-1" }
const _hoisted_13 = { class: "flex flex-row mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_ctx.notificationParams)
    ? (_openBlock(), _createBlock(_component_Dialog, {
        key: 0,
        visible: _ctx.displayed,
        "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.displayed) = $event)),
        showHeader: false,
        closeOnEscape: false,
        modal: !['info', 'success', 'muted'].includes(_ctx.notificationParams.type),
        position: ['info', 'success', 'muted'].includes(_ctx.notificationParams.type) ? 'top' : 'center',
        closable: _ctx.notificationParams.type == 'error' || _ctx.notificationParams.showCancel,
        onHide: _cache[7] || (_cache[7] = ($event: any) => (_ctx.hide(_ctx.notificationParams.type == 'error' && !_ctx.notificationParams.showCancel ? _ctx.BONotificationValue.OK : _ctx.BONotificationValue.CANCEL))),
        style: {"min-width":"30%"},
        contentClass: `border-${_ctx.notificationParams.type} overflow-hidden`
      }, {
        default: _withCtx(() => [
          (_ctx.sending)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["flex justify-content-center toaster-container-title m-4 mt-5", `p-${_ctx.notificationParams.type}`])
          }, [
            (_ctx.notificationParams.icon)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: _normalizeClass(["mr-3", _ctx.notificationParams.icon])
                }, null, 2))
              : (_ctx.notificationParams.type == 'info')
                ? (_openBlock(), _createElementBlock("i", _hoisted_2))
                : (_ctx.notificationParams.type == 'error')
                  ? (_openBlock(), _createElementBlock("i", _hoisted_3))
                  : (_ctx.notificationParams.type == 'success')
                    ? (_openBlock(), _createElementBlock("i", _hoisted_4))
                    : (_ctx.notificationParams.type == 'warning')
                      ? (_openBlock(), _createElementBlock("i", _hoisted_5))
                      : (_ctx.notificationParams.type == 'muted')
                        ? (_openBlock(), _createElementBlock("i", _hoisted_6))
                        : (_ctx.notificationParams.type == 'question')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_7))
                          : _createCommentVNode("", true),
            _createElementVNode("span", null, _toDisplayString(_ctx.notificationParams.title ?? _ctx.filters.cflt(`toaster_title_${_ctx.notificationParams.type}`)), 1)
          ], 2),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.notificationParams.subtitle)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "flex justify-content-center text-center toaster-container-subtitle mx-4",
                  innerHTML: _ctx.notificationParams.subtitle ?? _ctx.filters.cflt(`toaster_subTitle_${_ctx.notificationParams.type}`)
                }, null, 8, _hoisted_9))
              : _createCommentVNode("", true),
            (_ctx.notificationParams.message && (!_ctx.notificationParams.showMore || _ctx.notificationParams.showMore && _ctx.more))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["toaster-container-message", { 'text-center': _ctx.notificationParams.centered }]),
                  innerHTML: _ctx.notificationParams.message
                }, null, 10, _hoisted_10))
              : _createCommentVNode("", true),
            (_ctx.notificationParams.footer)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: "flex justify-content-center toaster-container-footer mx-4",
                  innerHTML: _ctx.notificationParams.footer ?? _ctx.filters.cflt(`toaster_footer.${_ctx.notificationParams.type}`)
                }, null, 8, _hoisted_11))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", null, [
              (_ctx.notificationParams.showSend)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    class: "mr-2 border-0 button-error",
                    disabled: _ctx.sending,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.send())),
                    innerHTML: _ctx.notificationParams.htmlSend ?? _ctx.filters.cflt(`toaster_button_send`)
                  }, null, 8, ["disabled", "innerHTML"]))
                : _createCommentVNode("", true),
              (_ctx.notificationParams.showMore)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 1,
                    class: "mr-2 border-0 button-error",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.more = !_ctx.more)),
                    innerHTML: _ctx.more ? _ctx.notificationParams.htmlLess ?? _ctx.filters.cflt(`toaster_button_less`) :
                               _ctx.notificationParams.htmlMore ?? _ctx.filters.cflt(`toaster_button_more`)
                  }, null, 8, ["innerHTML"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_13, [
              (_ctx.notificationParams.showNo)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    class: "ml-2 border-0",
                    disabled: _ctx.sending,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hide(_ctx.BONotificationValue.NO))),
                    innerHTML: _ctx.notificationParams.htmlNo ?? _ctx.filters.cflt(`toaster_button_no`)
                  }, null, 8, ["disabled", "innerHTML"]))
                : _createCommentVNode("", true),
              (_ctx.notificationParams.showYes)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 1,
                    class: "ml-2 border-0",
                    disabled: _ctx.sending,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.hide(_ctx.BONotificationValue.YES))),
                    innerHTML: _ctx.notificationParams.htmlYes ?? _ctx.filters.cflt(`toaster_button_yes`)
                  }, null, 8, ["disabled", "innerHTML"]))
                : _createCommentVNode("", true),
              (_ctx.notificationParams.showCancel)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 2,
                    disabled: _ctx.sending,
                    class: "ml-2 border-0",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.hide(_ctx.BONotificationValue.CANCEL))),
                    innerHTML: _ctx.notificationParams.htmlCancel ?? _ctx.filters.cflt(`toaster_button_cancel`)
                  }, null, 8, ["disabled", "innerHTML"]))
                : _createCommentVNode("", true),
              (_ctx.notificationParams.showOK)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 3,
                    disabled: _ctx.sending,
                    class: _normalizeClass(["ml-2 border-0", `button-${_ctx.notificationParams.type}`]),
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.hide(_ctx.BONotificationValue.OK))),
                    innerHTML: _ctx.notificationParams.htmlOK ?? _ctx.filters.cflt(`toaster_button_ok`)
                  }, null, 8, ["disabled", "class", "innerHTML"]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }, 8, ["visible", "modal", "position", "closable", "contentClass"]))
    : _createCommentVNode("", true)
}